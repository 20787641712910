import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledBody, StyledHeader } from '../AddInputGoals/styles';
import { InfoCircle } from '../Journeys/styles';
import { InfoHead, FlexBox, InfoDescription } from './styles';
import {withTranslation} from 'react-i18next';

class InfoPopup extends Component {
  render() {
    const { showModal, onClose,t} = this.props;
    return (
      <StyledModal show={showModal} onHide={onClose}>
        <StyledHeader closeButton>
          <FlexBox>
            <InfoCircle height="40px" width="40px" radius="20px" info={1}>
              <div className="addbutton" >i</div>
            </InfoCircle>
          </FlexBox>
        </StyledHeader>
        <StyledBody>
          <FlexBox>
            <InfoHead>
              {t("My Wellbeing Points")}
            </InfoHead>
          </FlexBox>
        </StyledBody>
        <StyledBody>
          <FlexBox>
            <InfoDescription>
              {t("Earn Education Points by reading an article (+10) and passing a quiz (+15) and watching recipe videos (+15)")}
            </InfoDescription>
          </FlexBox>
        </StyledBody>
      </StyledModal>
    );
  }
}

InfoPopup.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  t: PropTypes.func
}

export default (withTranslation()(InfoPopup));
import styled from 'styled-components';

const InfoHead = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #159fca;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;

const InfoDescription = styled.div`
  font-size: 16px;
  letter-spacing: 0px;
  color: #999999;
  text-align: center;
  margin-bottom: 40px;
  padding-top: 20px;
`;

const FlexBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-item: center;
  justify-content: center;
  margin-top: 12px;
  padding: 0px 19%;
  @media(max-width: 500px) {
    padding: 0px 15px;
  }
`;

const TotalPoints = styled.div`
width: 100%;
display: flex;
background: #FFFFFF;
border-radius: 6px;
margin-bottom: 15px;
>div:first-child {
  width: 40%;
  >img{
    height: 131px;
    width: 100%;
  }
}
>div:last-child {
  width: 60%;
  margin: auto;
  >div:first-child{
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0D4270;
    padding: 15px;
    text-transform: none;
    text-align: center;
  }
  >div:last-child{
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 24px;
    color: #FD7175;
    padding: 15px;
    text-align: center;
  }
}
`;

const Title = styled.div`
 font-size: 18px;
 color: #0D4270;
 font-family: rubik-medium;
 margin-bottom: 15px;
 text-transform: capitalize;
`;

export { InfoHead, FlexBox, InfoDescription, TotalPoints, Title };
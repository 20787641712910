/* eslint-disable camelcase*/
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HeaderContainer, HeaderImage } from '../HealthTab/styles';
import { NumberFont, AdventureCard, InfoCircle, HeaderTitle, AdventureCardNew } from '../Journeys/styles';
import { CommonContainer } from '../EngagementSection/styles';
import { ImageUrl, educationAdventure } from '../../utils/constants';
import { getUnit } from '../../utils/methods';
import { fetchAdventureScoreBoard } from '../../redux/actions';
import {withTranslation} from 'react-i18next';
import { TotalPoints, Title } from './styles';
import LazyImage from '../common/LazyImage/LazyImage';
import Waiting from '../Waiting' ;
import SkeletonLoder from '../common/skeletonLoder';
// const InfoPopup = React.lazy(() => import('./InfoPopup'));
// const DailyChecklist = React.lazy(() => import('../DailyChecklistNewWellness'));
import InfoPopup from './InfoPopup';
import DailyChecklist from '../DailyChecklistNewWellness';

const scoresDetails = [
  {
    color: '#69C2FF',
    width: '100%',
    value: 20,
    content: 'out of 30 lifestyle adventures completed',
    image: 'fitnessNew.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 10,
    content: 'out of 25 readings completed',
    image: 'reciprnew.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'out of 25 quizzes completed',
    image: 'educationnewNew.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'out of 25 quizzes completed',
    image: 'tip.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'Daily Tips Seen',
    image: 'quiznew.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2,
    content: 'Daily Tip Points Earned',
    image: 'fitnessvideow.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 12,
    content: 'perfect score on the quizzes',
    image: 'recipevideow.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 35,
    content: 'out of 42 questions quiz score total',
    image: 'ReadingCompletedn.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 450,
    content: 'Education Adventure points earned',
    image: 'educationNew.png',
    overlap: false
  },
  {
    color: '#69C2FF',
    width: '100%',
    value: 2000,
    content: 'Education Adventure points available',
    image: 'pQuizzesNew.png',
    overlap: false
  },
];

const scoresDetailsNew = [
  {
    color: 'rgb(21,159,202)',
    width: '100%',
    value: 20,
    content: 'out of 30 lifestyle adventures completed',
    image: 'totalPointsNew.png',
    overlap: false
  }
];


class AdventurePointDashBoard extends React.Component {
  constructor() {
    super();
    this.state = {
      scoresDetails: null,
      scoresDetailsNew: null,
      openPopup: false,
      loderSidetabContentArray : [],
    };
  }

  popupAction = () => {
    this.setState({
      openPopup: !this.state.openPopup,
    });
  };

  componentDidMount = () => {
    const newArray = new Array(10).fill(null).map((_, index) => `Item ${index + 1}`);
    this.setState({ loderSidetabContentArray: newArray });
    const { adventureScoreBoard, fetchAdventureScoreBoard } = this.props;
    if (!adventureScoreBoard) {
      fetchAdventureScoreBoard();
    } else {
      this.setScore(adventureScoreBoard);
      this.setScoreNew(adventureScoreBoard);
    }
  };

  setScore = (adventureScoreBoard) => {
    let arr = [...scoresDetails];
    for (let i = 0; i < arr.length; i++) {
      switch (i) {
      case 0:
        arr[i].value = adventureScoreBoard.fitness_videos_point;
        arr[i].content = `${this.props.t("Fitness Video Points")}`;
        break;
      case 1:
        arr[i].value = adventureScoreBoard.recipe_videos_point;
        arr[i].content = `${this.props.t("Recipe Video Points")}`;
        break;
      case 2:
        arr[i].value = adventureScoreBoard.read_journeys_point;
        arr[i].content = `${this.props.t("Article Points")}`;
        break;
      case 3:
        arr[i].value = adventureScoreBoard.daily_tip_points;
        arr[i].content = `${this.props.t("Daily Tip Points")}`;
        break;
      case 4:
        arr[i].value = adventureScoreBoard.quiz_score_total;
        arr[i].content = `${this.props.t("Quiz Points")}`;
        break;
      case 5:
        arr[i].value = adventureScoreBoard.fitness_videos_count;
        arr[i].content = `${this.props.t("Fitness Video Watched")}`;
        break;
      case 6:
        arr[i].value = adventureScoreBoard.recipe_videos_count;
        arr[i].content = `${this.props.t("Recipe Video Watched")}`;
        break;
      case 7:
        arr[i].value = adventureScoreBoard.read_journeys_count;
        arr[i].slash = `${this.props.t("/")}`;
        arr[i].valueNew = adventureScoreBoard.all_journeys_count;
        arr[i].content = `${this.props.t("Articles Read")}`;
        break;
      case 8:
        arr[i].value = adventureScoreBoard.completed_quizzes_count;
        arr[i].slash = `${this.props.t("/")}`;
        arr[i].valueNew = adventureScoreBoard.all_quizzes_count;
        arr[i].content = `${this.props.t("Quizzes Passed")}`;
        break;
      case 9:
        arr[i].value = adventureScoreBoard.perfect_quizzes_count;
        arr[i].content = `${this.props.t("Perfect Quizzes")}`;
        break;
      }
    }
    this.setState({
      scoresDetails: arr,
    });
  };

  setScoreNew = (adventureScoreBoard) => {
    let arr = [...scoresDetailsNew];
    for (let i = 0; i < arr.length; i++) {
      switch (i) {
      case 0:
        arr[i].value = adventureScoreBoard.total_points_earned;
        arr[i].content = `Total Points Earned`;
        break;
      }
    }
    this.setState({
      scoresDetailsNew: arr,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { adventureScoreBoard, fetchAdventureScoreBoard } = nextProps;
    if (adventureScoreBoard) {
      this.setScore(adventureScoreBoard);
      this.setScoreNew(adventureScoreBoard);
    } else {
      fetchAdventureScoreBoard();
    }
  };

  getModelLogo = () => <HeaderImage src="/public/images/Logo/modal-logo.png" />;

  renderHeader = (title, showCircle) => (
    <HeaderContainer>
      <div>
        {this.getModelLogo()}
        <HeaderTitle>{title}</HeaderTitle>
      </div>
      {showCircle && (
        <InfoCircle onClick={() => this.popupAction()}>
          <div className="addbutton">i</div>
        </InfoCircle>
      )}
    </HeaderContainer>
  );

  getEventDetails = (scoresDetails) =>
    scoresDetails.map((data, index) => (
      <AdventureCard key={index} width={data.width}>
        <div className="card">
          <div className="flex">
            <div className="block">
              <LazyImage
                className={index === 3 || index === 4 ? "imgWidth" : undefined}
                src={educationAdventure + data.image}
                alt="no-data"
                style={{ width: "auto", height: "40px" }}
              />
              {data.overlap && (
                <div className="overlap">
                  <img src={educationAdventure + "cup.png"} alt="no-data" />
                </div>
              )}
            </div>
            <div className="text">{this.props.t(data.content)}</div>
            <NumberFont color={data.color}>
              {getUnit(Math.round(data.value))}
            </NumberFont>
          </div>
        </div>
      </AdventureCard>
    ));

  getTotalPoints = () => {
    const { adventureScoreBoard, t } = this.props;
    return (
      <TotalPoints>
        <LazyImage src={ImageUrl + "/images/educationAdventureIcons/totalPointsNew.png"}/>
        <div>
          <div>{t("Total Points Earned")}</div>
          <div>{getUnit(adventureScoreBoard.total_points_earned)}</div>
        </div>
      </TotalPoints>
    );
  };

  getEventDetailsNew = (scoresDetailsNew) =>
    scoresDetailsNew.map((data, index) => (
      <AdventureCardNew key={index} width={data.width}>
        <div className="card">
          <div className="flex">
            <div className="block">
              <img
                className={index === 3 || index === 4 ? "imgWidth" : undefined}
                src={educationAdventure + data.image}
                alt="no-data"
              />
            </div>
            <div className="text">
              <span>{this.props.t(data.content)}</span>

              <p style={{ color: "#FD7175", "font-size": "30px" }}>
                {getUnit(data.value)}
                <span style={{ color: "black", "font-size": "10px" }}>
                  /{getUnit(data.value)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </AdventureCardNew>
    ));

  renderSideBar = () => {
    const { themesHeader } = this.props;
    return (
      <React.Suspense fallback={<Waiting />}>
        <DailyChecklist themesHeader={themesHeader} />
      </React.Suspense>
    );
  };

  render() {
    const { adventureScoreBoard, t } = this.props;
    const { scoresDetails, openPopup } = this.state;
    if (!adventureScoreBoard || !scoresDetails) {
      return <div style={{width:'385px'}}>
        <SkeletonLoder width={"100%"} height={"50px"} style={{margin:'0px 0px 25px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"100px"} style={{margin:'0px 0px 25px 0px'}}/>
        <SkeletonLoder width={"250px"} height={"24px"} style={{margin:'0px 0px 15px 0px'}}/>
        <SkeletonLoder width={"100%"} height={"131px"} style={{margin:'0px 0px 15px 0px'}}/>
        <div>
          {this.state.loderSidetabContentArray.map((item, index) => (
            <SkeletonLoder key={index} width={"100%"} height={"60px"} style={{margin:'0px 0px 5px 0px'}}/>
          ))}
        </div>
      </div>;
    }
    return (
      <div>
        <div>
          <CommonContainer marginTop="1px" float={1} smallPadding="15px">
            {this.renderSideBar()}
            <Title>{t("My Wellness Points")}</Title>
            {this.getTotalPoints()}
            {this.getEventDetails(scoresDetails)}
          </CommonContainer>
        </div>
        <React.Suspense fallback={<Waiting/>}>
          <InfoPopup onClose={() => this.popupAction()} showModal={openPopup} />
        </React.Suspense> 
      </div>
    );
  }
}

AdventurePointDashBoard.propTypes = {
  adventureScoreBoard: PropTypes.object,
  fetchAdventureScoreBoard: PropTypes.func.isRequired,
  t: PropTypes.func,
  themesHeader: PropTypes.array,
}

const mapStateToProps = (state) => ({
  adventureScoreBoard: state.education.adventureScoreBoard,
  themesHeader: state.profileData.themesHeader,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdventureScoreBoard: () => dispatch(fetchAdventureScoreBoard())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdventurePointDashBoard));